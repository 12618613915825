import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import { CustomerService } from '../../backend-services/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-customer',
  templateUrl: './manage-single-customer.component.html',
  styleUrls: [
    './manage-single-customer.component.scss',
  ],
})
export class ManageSingleCustomerComponent {
  @ViewChild('confirmBackModalTrigger') confirmBackModalTrigger:
    | ElementRef
    | undefined;

  customerForm = new FormGroup({
    label: new FormControl('', []),
    company: new FormControl('', []),
    vat_nr: new FormControl(''),
    first_name: new FormControl('', []),
    last_name: new FormControl('', []),
    email: new FormControl('', [Validators.email]),
    billing_street: new FormControl('', []),
    billing_house_number: new FormControl('', []),
    billing_zip: new FormControl('', []),
    billing_city: new FormControl('', []),
    billing_email: new FormControl('', [Validators.email]),
    invoice_by_mail: new FormControl(true, []),
  });
  customerFormLoading = false;

  id: number | null = null;

  initialData: any = undefined;

  constructor(
    private _location: Location,
    private customerService: CustomerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.initialData = this.customerForm.value;

    this.route.paramMap.subscribe({
      next: (val) => {
        const id = val.get('id');
        if (id) {
          this.id = parseInt(id);
          this.customerFormLoading = true;
          this.customerForm.disable();

          this.customerService.getCustomerById(this.id).subscribe({
            next: (res) => {
              if (res) {
                this.customerFormLoading = false;

                // set form values
                this.labelControl.setValue(res.label);
                this.companyControl.setValue(res.company);
                this.vatControl.setValue(res.vat_nr);
                this.firstNameControl.setValue(res.first_name);
                this.lastNameControl.setValue(res.last_name);
                this.emailControl.setValue(res.email);
                this.billingStreetControl.setValue(res.billing_street);
                this.billingHouseNumberControl.setValue(
                  res.billing_house_number
                );
                this.billingZipControl.setValue(res.billing_zip);
                this.billingCityControl.setValue(res.billing_city);
                this.billingEmailControl.setValue(res.billing_email);

                this.customerForm
                  .get('invoice_by_mail')!
                  .setValue(res.invoice_by_mail);

                this.customerForm.enable();

                this.initialData = this.customerForm.value;
              }
            },
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                this.translocoService.translate(`User Not Found`)
              );
            },
          });
        }
      },
    });
  }

  get labelControl() {
    return this.customerForm.get('label') as AbstractControl;
  }

  get companyControl() {
    return this.customerForm.get('company') as AbstractControl;
  }

  get vatControl() {
    return this.customerForm.get('vat_nr') as AbstractControl;
  }

  get firstNameControl() {
    return this.customerForm.get('first_name') as AbstractControl;
  }

  get lastNameControl() {
    return this.customerForm.get('last_name') as AbstractControl;
  }

  get emailControl() {
    return this.customerForm.get('email') as AbstractControl;
  }

  get billingStreetControl() {
    return this.customerForm.get('billing_street') as AbstractControl;
  }

  get billingHouseNumberControl() {
    return this.customerForm.get('billing_house_number') as AbstractControl;
  }

  get billingZipControl() {
    return this.customerForm.get('billing_zip') as AbstractControl;
  }

  get billingCityControl() {
    return this.customerForm.get('billing_city') as AbstractControl;
  }

  get billingEmailControl() {
    return this.customerForm.get('billing_email') as AbstractControl;
  }

  handleSubmit() {
    this.customerForm.markAllAsTouched();

    if (this.customerForm.valid) {
      this.customerFormLoading = true;

      if (!this.id) {
        this.customerService.createCustomer(this.customerForm.value).subscribe({
          next: (res) => {
            this.customerFormLoading = false;
            if (res.success) {
              this.toastr.success(
                this.translocoService.translate(
                  `Neuer Kunde erfolgreich erstellt!`
                )
              );
              this.router.navigate([`/user-management/`], {
                replaceUrl: true,
                queryParams: {
                  management_type: 'customers',
                },
              });
            }
          },
          error: (res) => {
            console.log('err', res.error);
            this.toastr.error(
              res.error?.error ||
                this.translocoService.translate(`Something went wrong`)
            );
            this.customerFormLoading = false;
          },
        });
      } else {
        this.customerService
          .updateCustomer(this.id, this.customerForm.value)
          .subscribe({
            next: (res) => {
              this.customerFormLoading = false;
              if (res.success) {
                this.customerForm.markAsUntouched();
                this.toastr.success(
                  this.translocoService.translate(
                    `Ihr Profil wurde gespeichert`
                  )
                );

                this.router.navigate([`/user-management/`], {
                  replaceUrl: true,
                  queryParams: {
                    management_type: 'customers',
                  },
                });
              }
            },
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.error ||
                  this.translocoService.translate(`Something went wrong`)
              );
              this.customerFormLoading = false;
            },
          });
      }
    }
  }

  goBackCheck() {
    if (
      JSON.stringify(this.initialData) !==
      JSON.stringify(this.customerForm.value)
    ) {
      this.confirmBackModalTrigger?.nativeElement?.click();
    } else {
      this.goBack();
    }
  }

  goBack() {
    this._location.back();
  }
}
