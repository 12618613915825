<div class="container mx-auto">
  <div class="w-full flex items-center justify-between flex-wrap gap-2">
    <div *ngIf="!currentFolderId">
      <h1 class="text-2xl font-bold text-black" transloco="Requests">
        Requests
      </h1>
    </div>
    <div *ngIf="currentFolderId" class="flex items-center gap-4">
      <div
        class="w-20 h-20 border-gray3 flex items-center justify-center border rounded-lg"
      >
        <svg
          width="43"
          height="32"
          viewBox="0 0 43 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.9375 11.745C41.6591 11.359 41.2928 11.0448 40.869 10.8283C40.4452 10.6118 39.9759 10.4993 39.5 10.5H36.5V7.5C36.5 6.70435 36.1839 5.94129 35.6213 5.37868C35.0587 4.81607 34.2957 4.5 33.5 4.5H20.5006L15.3013 0.6C14.7811 0.212217 14.1501 0.00187504 13.5012 0H3.5C2.70435 0 1.94129 0.31607 1.37868 0.87868C0.81607 1.44129 0.5 2.20435 0.5 3V30C0.5 30.3978 0.658035 30.7794 0.93934 31.0607C1.22064 31.342 1.60218 31.5 2 31.5H35.5813C35.8961 31.5 36.2029 31.401 36.4584 31.2169C36.7138 31.0328 36.9048 30.7731 37.0044 30.4744L42.3462 14.4487C42.4965 13.9978 42.5377 13.5177 42.4667 13.0478C42.3957 12.5778 42.2143 12.1314 41.9375 11.745ZM13.5012 3L18.7006 6.9C19.2207 7.28802 19.8518 7.49839 20.5006 7.5H33.5V10.5H9.08187C8.4522 10.5 7.83848 10.698 7.32764 11.0662C6.8168 11.4343 6.43476 11.9539 6.23563 12.5513L3.5 20.7562V3H13.5012ZM34.5013 28.5H4.08125L9.08187 13.5H39.5L34.5013 28.5Z"
            fill="black"
          />
        </svg>
      </div>
      <div class="flex flex-col" *ngIf="currentFolderData">
        <div class="flex items-center gap-1">
          <h1 class="text-2xl font-bold text-primary">
            {{ currentFolderData?.title }}
          </h1>
          <button
            class="font-semibold text-primary text-xs 2xl:text-sm transition-all duration-200 hover:bg-gray-100 rounded-full p-2"
            (click)="openFolderEditModal()"
          >
            <img src="assets/imgs/icons/edit-icon.svg" alt="edit" />
          </button>
        </div>
        <ul class="flex items-center gap-2">
          <li>
            <a href="/requests">
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.528764 5.47157C0.403783 5.34655 0.333572 5.17702 0.333572 5.00024C0.333572 4.82346 0.403783 4.65392 0.528764 4.52891L4.3001 0.757572C4.36159 0.693899 4.43516 0.643111 4.51649 0.608171C4.59783 0.573232 4.68531 0.554841 4.77383 0.554072C4.86235 0.553303 4.95014 0.570171 5.03207 0.603691C5.114 0.637212 5.18843 0.686714 5.25103 0.749309C5.31362 0.811904 5.36312 0.886339 5.39664 0.968269C5.43017 1.0502 5.44703 1.13799 5.44626 1.22651C5.44549 1.31503 5.4271 1.40251 5.39216 1.48384C5.35723 1.56518 5.30644 1.63874 5.24276 1.70024L1.94276 5.00024L5.24276 8.30024C5.3642 8.42598 5.4314 8.59438 5.42988 8.76917C5.42836 8.94397 5.35825 9.11118 5.23464 9.23479C5.11104 9.35839 4.94383 9.4285 4.76903 9.43002C4.59423 9.43154 4.42583 9.36435 4.3001 9.24291L0.528764 5.47157Z"
                  fill="black"
                />
              </svg>
            </a>
          </li>
          <li class="font-normal text-xs text-gray4">
            <a routerLink="/requests" transloco="Requests">Requests</a>
          </li>
          <li
            class="font-semibold text-xs 2xl:text-small"
            [ngClass]="{ 'text-primary': last, 'text-gray4': !last }"
            *ngFor="let pathItem of currentFolderData.path; let last = last"
          >
            <div *ngIf="last" class="font-semibold">
              / {{ pathItem?.title }}
            </div>
            <a
              [routerLink]="'/requests/folders/' + pathItem.id"
              *ngIf="!last"
              class="font-normal text-xs text-gray4"
            >
              / {{ pathItem?.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="flex gap-4">
      <button
        type="button"
        (click)="openCreateFolderModal()"
        class="tw-btn-primary-outline"
        transloco="+ New Folder"
      >
        + New Folder
      </button>
      <a
        type="button"
        [href]="
          currentFolderId
            ? '/requests/new?request_folder_id=' + currentFolderId
            : '/requests/new'
        "
        class="tw-btn-secondary-outline"
        transloco="+ New Request"
      >
        + New Request
      </a>
    </div>
  </div>
  <div class="w-full flex mt-6 flex-wrap gap-4 items-center" *transloco="let t">
    <div class="relative min-w-[380px] flex-grow">
      <input
        type="search"
        [formControl]="searchControl"
        id="default-search"
        class="block w-full p-3 2xl:p-4 pe-14 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
        [placeholder]="t('Search by folder, name or company...')"
      />
      <button
        (click)="doSearch()"
        class="absolute right-0.5 top-1/2 -translate-y-1/2 rounded-lg px-4 py-2"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.9992 21L16.6992 16.7"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div>
      <button
        id="dropdownRadioButton"
        data-dropdown-toggle="dropdownRadio"
        class="border border-gray-300 rounded-lg p-3 2xl:p-4 items-center font-medium text-gray2 hover:bg-gray-50 text-xs 2xl:text-sm min-w-[320px] flex"
        type="button"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="mr-2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 5.6C20 5.04 20 4.76 19.89 4.546C19.7945 4.35792 19.6419 4.20493 19.454 4.109C19.24 4 18.96 4 18.4 4H5.6C5.04 4 4.76 4 4.546 4.109C4.35785 4.20487 4.20487 4.35785 4.109 4.546C4 4.76 4 5.04 4 5.6V6.337C4 6.582 4 6.704 4.028 6.819C4.05249 6.92138 4.09299 7.01925 4.148 7.109C4.209 7.209 4.296 7.296 4.468 7.469L9.531 12.531C9.704 12.704 9.791 12.791 9.852 12.891C9.907 12.981 9.948 13.079 9.972 13.181C10 13.295 10 13.416 10 13.655V18.411C10 19.268 10 19.697 10.18 19.955C10.2581 20.0666 10.3582 20.161 10.4741 20.2326C10.59 20.3041 10.7192 20.3512 10.854 20.371C11.165 20.417 11.549 20.226 12.315 19.842L13.115 19.442C13.437 19.282 13.597 19.202 13.714 19.082C13.8178 18.976 13.8967 18.8483 13.945 18.708C14 18.55 14 18.37 14 18.011V13.663C14 13.418 14 13.296 14.028 13.181C14.0525 13.0786 14.093 12.9807 14.148 12.891C14.208 12.791 14.295 12.705 14.465 12.535L14.469 12.531L19.532 7.469C19.704 7.296 19.79 7.209 19.852 7.109C19.9071 7.0193 19.9476 6.92141 19.972 6.819C20 6.706 20 6.584 20 6.345V5.6Z"
            stroke="#8091A7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div transloco="Status" class="font-semibold text-sm">Status:</div>
        <div class="text-black font-semibold text-sm ml-2">
          {{ selectedStatusTitle }}
        </div>
        <div class="grow flex-grow-1 flex justify-end">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_17_845)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7083 15.707C12.5208 15.8944 12.2665 15.9998 12.0013 15.9998C11.7362 15.9998 11.4818 15.8944 11.2943 15.707L5.63732 10.05C5.54181 9.95773 5.46563 9.84739 5.41322 9.72538C5.36081 9.60338 5.33322 9.47216 5.33207 9.33938C5.33092 9.2066 5.35622 9.07492 5.4065 8.95202C5.45678 8.82913 5.53103 8.71747 5.62492 8.62358C5.71882 8.52969 5.83047 8.45544 5.95337 8.40515C6.07626 8.35487 6.20794 8.32957 6.34072 8.33073C6.4735 8.33188 6.60472 8.35947 6.72672 8.41188C6.84873 8.46428 6.95907 8.54047 7.05132 8.63598L12.0013 13.586L16.9513 8.63598C17.1399 8.45382 17.3925 8.35302 17.6547 8.3553C17.9169 8.35758 18.1677 8.46275 18.3531 8.64816C18.5385 8.83357 18.6437 9.08438 18.646 9.34658C18.6483 9.60877 18.5475 9.86137 18.3653 10.05L12.7083 15.707Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_845">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </button>
      <!-- Dropdown menu -->
      <div
        id="dropdownRadio"
        class="z-50 hidden min-w-[320px] bg-white divide-y divide-gray-100 rounded-lg shadow drop-shadow-xl dark:bg-gray-700 dark:divide-gray-600"
        data-popper-reference-hidden=""
        data-popper-escaped=""
        data-popper-placement="top"
        style="position: absolute; inset: auto auto 0px 0px; margin: 0px"
      >
        <ul
          class="p-3 space-y-1 text-xs 2xl:text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownRadioButton"
        >
          <li>
            <div
              class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
            >
              <input
                id="filter-radio-all"
                type="radio"
                value="all"
                [formControl]="statusFilterControl"
                name="filter-radio"
                class="w-4 h-4 text-primaryBtn bg-gray-100 border-gray-300 focus:ring-primaryBtn dark:focus:ring-primaryBtn dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="filter-radio-all"
                class="w-full ms-2 text-lg font-medium text-gray-900 rounded dark:text-gray-300"
                transloco="All Status"
                >All Status</label
              >
            </div>
          </li>
          <ng-container *ngFor="let item of status_list">
            <li>
              <div
                class="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <input
                  id="{{ item.id }}"
                  type="radio"
                  value="{{ item.id }}"
                  [formControl]="statusFilterControl"
                  name="filter-radio"
                  class="w-4 h-4 text-primaryBtn bg-gray-100 border-gray-300 focus:ring-primaryBtn dark:focus:ring-primaryBtn dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="{{ item.id }}"
                  class="w-full ms-2 text-lg font-medium text-gray-900 rounded dark:text-gray-300"
                  >{{ item.title }}</label
                >
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="overflow-x-auto shadow-md sm:rounded-lg"
    *transloco="let t"
  >
    <table class="w-full mt-4 text-xs 2xl:text-sm text-left text-gray-500">
      <thead class="text-xs 2xl:text-sm text-black font-semibold">
        <tr>
          <th
            scope="col"
            class="border-l border-t border-b border-gray3 px-6 py-3"
          >
            <app-sortable-table-header
              displayName="#"
              fieldName="project_number"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 rounded-l"
          >
            <app-sortable-table-header
              [displayName]="t('Name')"
              fieldName="title"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-6 py-3 w-1/7"
          >
            <app-sortable-table-header
              [displayName]="t('Client')"
              fieldName="client_id"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7"
          >
            <app-sortable-table-header
              [displayName]="t('Status')"
              fieldName="status"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Due Date')"
              fieldName="due_date"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7 break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Updated At')"
              fieldName="updated_at"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3 w-1/7 whitespace-nowrap break-all"
            [ngClass]="{
              'whitespace-nowrap': translocoService.getActiveLang() === 'en'
            }"
          >
            <app-sortable-table-header
              [displayName]="t('Created At')"
              fieldName="created_at"
            >
            </app-sortable-table-header>
          </th>
          <th
            scope="col"
            class="border-t border-b border-gray3 px-2 py-3"
            transloco="File"
          >
            File
          </th>
          <th
            scope="col"
            class="border-t border-b border-r rounded-r border-gray3 px-2 py-3 text-center"
            transloco="Actions"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let data of requestFolders">
          <tr
            class="bg-white text-xs 2xl:text-sm border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            data-expanded="false"
            id="{{ data.id }}"
          >
            <td></td>
            <td
              colspan="8"
              class="px-6 py-4 font-medium text-gray4 whitespace-nowrap truncate cursor-pointer"
            >
              <a
                class="flex gap-6 items-center w-full"
                [routerLink]="'/requests/folders/' + data.id"
              >
                <svg
                  width="22"
                  height="17"
                  viewBox="0 0 22 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.9688 6.3725C20.8295 6.17951 20.6464 6.0224 20.4345 5.91416C20.2226 5.80592 19.988 5.74965 19.75 5.75H18.25V4.25C18.25 3.85218 18.092 3.47064 17.8107 3.18934C17.5294 2.90804 17.1478 2.75 16.75 2.75H10.2503L7.65063 0.8C7.39054 0.606109 7.07503 0.500938 6.75062 0.5H1.75C1.35218 0.5 0.970644 0.658035 0.68934 0.93934C0.408035 1.22064 0.25 1.60218 0.25 2V15.5C0.25 15.6989 0.329018 15.8897 0.46967 16.0303C0.610322 16.171 0.801088 16.25 1 16.25H17.7906C17.948 16.25 18.1015 16.2005 18.2292 16.1085C18.3569 16.0164 18.4524 15.8865 18.5022 15.7372L21.1731 7.72437C21.2482 7.49891 21.2689 7.25886 21.2334 7.02388C21.1978 6.78891 21.1072 6.56569 20.9688 6.3725ZM6.75062 2L9.35031 3.95C9.61033 4.14401 9.92589 4.2492 10.2503 4.25H16.75V5.75H4.54094C4.2261 5.74998 3.91924 5.84902 3.66382 6.03309C3.4084 6.21717 3.21738 6.47695 3.11781 6.77563L1.75 10.8781V2H6.75062ZM17.2506 14.75H2.04062L4.54094 7.25H19.75L17.2506 14.75Z"
                    fill="black"
                  />
                </svg>
                <div class="flex flex-col text-sm font-semibold text-primary">
                  <div>{{ data.title }}</div>
                </div>
              </a>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let data of requests">
          <tr
            class="bg-white text-xs 2xl:text-sm border-b hover:bg-gray-50 dark:hover:bg-gray-600"
            data-expanded="false"
            id="{{ data.id }}"
          >
            <td
              class="px-3 py-4 font-medium text-gray4 whitespace-nowrap truncate max-w-[100px]"
            >
              {{ data.project_number }}
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-base text-gray4 whitespace-nowrap max-w-[300px]"
            >
              <a
                [routerLink]="'/requests/edit/' + data.id"
                class="flex items-center gap-6"
              >
                <div class="h-6 w-6">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.464 20.536C4.93 22 7.286 22 12 22C16.714 22 19.071 22 20.535 20.535C22 19.072 22 16.714 22 12C22 7.286 22 4.929 20.535 3.464C19.072 2 16.714 2 12 2C7.286 2 4.929 2 3.464 3.464C2 4.93 2 7.286 2 12C2 16.714 2 19.072 3.464 20.536ZM7.565 13.068C7.43412 12.9182 7.24907 12.8264 7.05056 12.813C6.85205 12.7996 6.65635 12.8656 6.5065 12.9965C6.35665 13.1274 6.26494 13.3124 6.25153 13.5109C6.23812 13.7094 6.30412 13.9052 6.435 14.055L9.435 17.492C9.53533 17.6069 9.66832 17.6885 9.81623 17.7259C9.96414 17.7633 10.1199 17.7547 10.2628 17.7012C10.4057 17.6478 10.5289 17.552 10.6159 17.4267C10.703 17.3014 10.7498 17.1526 10.75 17V7C10.75 6.80109 10.671 6.61032 10.5303 6.46967C10.3897 6.32902 10.1989 6.25 10 6.25C9.80109 6.25 9.61032 6.32902 9.46967 6.46967C9.32902 6.61032 9.25 6.80109 9.25 7V15L7.565 13.068ZM13.737 6.297C13.5939 6.3506 13.4705 6.44665 13.3835 6.57229C13.2964 6.69793 13.2499 6.84716 13.25 7V17C13.25 17.1989 13.329 17.3897 13.4697 17.5303C13.6103 17.671 13.8011 17.75 14 17.75C14.1989 17.75 14.3897 17.671 14.5303 17.5303C14.671 17.3897 14.75 17.1989 14.75 17V9L16.435 10.93C16.567 11.0753 16.7507 11.163 16.9467 11.1744C17.1427 11.1858 17.3353 11.1199 17.4832 10.9908C17.6311 10.8617 17.7225 10.6798 17.7378 10.4841C17.7531 10.2884 17.6911 10.0945 17.565 9.944L14.565 6.507C14.4648 6.3919 14.3318 6.31013 14.1838 6.2726C14.0359 6.23508 13.88 6.24359 13.737 6.297Z"
                    />
                  </svg>
                </div>
                <span class="truncate">
                  {{ data.title }}
                </span>
              </a>
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 text-sm font-semibold text-gray4 whitespace-nowrap truncate max-w-[200px]"
            >
              {{ data.client.company_name }}
            </td>
            <td
              class="pr-3 py-4 font-medium text-xs text-primary whitespace-nowrap"
            >
              <div
                class="flex items-center gap-3 border py-2 px-4 w-fit rounded-lg"
                [ngClass]="{
                  'border-yellow1 fill-yellow1': data.status.id === 1,
                  'border-light-blue1 fill-light-blue1': data.status.id === 2,
                  'border-blue1 fill-blue1': data.status.id === 3,
                  'border-orange1 fill-orange1': data.status.id === 4,
                  'border-purple-500 fill-purple-500': data.status.id === 5,
                  'border-gray3 fill-gray3': data.status.id === 6,
                  'border-green1 fill-green1': data.status.id === 7,
                  'border-red2 fill-red2': data.status.id === 8
                }"
              >
                <svg
                  width="6"
                  height="7"
                  viewBox="0 0 6 7"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3" cy="3.5" r="3" />
                </svg>
                <div class="text-xs 2xl:text-sm font-medium text-primary">
                  {{ data.status.title }}
                </div>
              </div>
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.due_date | date: "dd/MM/yyyy" }}
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.updated_at | date: "dd/MM/yyyy" }}
            </td>
            <td class="pr-3 py-4 font-semibold text-gray4 whitespace-nowrap">
              {{ data.created_at | date: "dd/MM/yyyy" }}
            </td>
            <td class="py-4 font-medium text-gray4 whitespace-nowrap">
              <app-request-list-file-preview
                [request]="data"
              ></app-request-list-file-preview>
            </td>
            <td
              class="px-4 py-2 2xl:px-6 2xl:py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <app-custom-dropdown [hasRawContent]="true">
                <button rawButton>
                  <img
                    src="/assets/imgs/icons/solar-menu-dots.svg"
                    alt="actions"
                  />
                </button>
                <ng-container>
                  <div
                    class="flex flex-col border border-gray3 rounded-lg bg-white min-w-[175px]"
                  >
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Share')"
                      (click)="openSendModal(data)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M227.32,28.68a16,16,0,0,0-15.66-4.08l-.15,0L19.57,82.84a16,16,0,0,0-2.49,29.8L102,154l41.3,84.87A15.86,15.86,0,0,0,157.74,248q.69,0,1.38-.06a15.88,15.88,0,0,0,14-11.51l58.2-191.94c0-.05,0-.1,0-.15A16,16,0,0,0,227.32,28.68ZM157.83,231.85l-.05.14,0-.07-40.06-82.3,48-48a8,8,0,0,0-11.31-11.31l-48,48L24.08,98.25l-.07,0,.14,0L216,40Z"
                        ></path>
                      </svg>
                      {{ t("Share") }}
                    </button>
                    <button
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      [title]="t('Copy link')"
                      (click)="copyPublicLinkToClipboard(data.public_hash)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
                        ></path>
                        <path
                          d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
                        ></path>
                      </svg>
                      {{ t("Copy Link") }}
                    </button>
                    <a
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 border-b px-4"
                      title="Duplicate request"
                      routerLink="/requests/new"
                      [queryParams]="{ duplicate_with: data.id }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"
                        ></path>
                      </svg>
                      {{ t("Duplicate") }}
                    </a>
                    <a
                      title="View"
                      [routerLink]="'/request/' + data.public_hash"
                      target="_blank"
                      class="font-semibold text-primary text-xs transition-all duration-200 hover:bg-gray-100 flex gap-2 w-full py-3 px-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000000"
                        viewBox="0 0 256 256"
                      >
                        <path
                          d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"
                        ></path>
                      </svg>
                      {{ t("View") }}
                    </a>
                  </div>
                </ng-container>
              </app-custom-dropdown>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <app-pagination [total]="totalRequestsCount"></app-pagination>
</div>
