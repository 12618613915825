import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../backend-services/auth.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  personalDataForm = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl('', [Validators.email]),
  });
  personalDataFormError?: string = undefined;
  personalDataFormIsSubmitting = false;

  passwordDataForm = new FormGroup({
    password: new FormControl(''),
    new_password: new FormControl('', [
      Validators.pattern(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    ]),
    confirm_password: new FormControl('', this.confirmPasswordValidator()),
  });
  INVALID_CURRENT_PASSWORD = false;
  passwordDataFormIsSubmitting = false;
  lastUpdatedAt?: string;

  modalEvents = new Subject<ModalEvents>();

  get firstNameControl() {
    return this.personalDataForm.get('first_name') as AbstractControl;
  }
  get lastNameControl() {
    return this.personalDataForm.get('last_name') as AbstractControl;
  }
  get emailControl() {
    return this.personalDataForm.get('email') as AbstractControl;
  }

  get passwordControl() {
    return this.passwordDataForm.get('password') as AbstractControl;
  }
  get newPasswordControl() {
    return this.passwordDataForm.get('new_password') as AbstractControl;
  }
  get confirmPasswordControl() {
    return this.passwordDataForm.get('confirm_password') as AbstractControl;
  }

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
    private _location: Location
  ) {
    authService.profile.subscribe((profile) => {
      this.personalDataForm.get('first_name')?.setValue(profile?.first_name);
      this.personalDataForm.get('last_name')?.setValue(profile?.last_name);
      this.personalDataForm.get('email')?.setValue(profile?.email);
      this.lastUpdatedAt = profile?.updated_at;
    });

    this.newPasswordControl.valueChanges.subscribe(() => {
      this.onPasswordChange();
    });

    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
      }
    });
  }

  confirmPasswordValidator(): ValidatorFn {
    return (
      confirmPasswordControl: AbstractControl
    ): { [key: string]: any } | null => {
      const passwordControl = this.passwordDataForm?.get('new_password');

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  onPasswordChange() {
    // validate confirm password
    const confirmPasswordValidatorFn = this.confirmPasswordControl.validator;
    if (confirmPasswordValidatorFn) {
      confirmPasswordValidatorFn(this.confirmPasswordControl);
    }
  }

  saveProfileData() {
    this.personalDataForm.markAllAsTouched();
    this.personalDataFormError = undefined;

    if (this.personalDataForm.valid) {
      this.personalDataFormIsSubmitting = true;
      this.personalDataForm.disable();

      this.authService.updateProfile(this.personalDataForm.value).subscribe({
        next: () => {
          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
          this.toastr.success(
            this.translocoService.translate(`Ihr Profil wurde gespeichert`)
          );
        },
        error: (res) => {
          this.personalDataFormError = res.error?.message;
          this.toastr.error(
            typeof res.error?.message === 'string'
              ? res.error.message
              : res.error.error
          );

          this.personalDataFormIsSubmitting = false;
          this.personalDataForm.enable();
        },
      });
    }
  }

  savePassword() {
    this.INVALID_CURRENT_PASSWORD = false;
    this.passwordDataForm.markAllAsTouched();

    if (this.passwordDataForm.valid) {
      this.passwordDataFormIsSubmitting = true;
      this.passwordDataForm.disable({ emitEvent: true });

      this.authService
        .changePassword({
          current_password: this.passwordDataForm.get('password')!.value,
          password: this.passwordDataForm.get('new_password')!.value,
        })
        .subscribe({
          next: () => {
            this.passwordDataFormIsSubmitting = false;
            this.passwordDataForm.enable({ emitEvent: true });

            this.toastr.success(
              this.translocoService.translate(`'Passwort wurde gespeichert`)
            );

            // reset the password form
            this.passwordDataForm.reset();
            this.modalEvents.next({ type: 'close' });
          },
          error: (res) => {
            if (res.error?.error === 'INVALID_CURRENT_PASSWORD') {
              this.INVALID_CURRENT_PASSWORD = true;
            } else {
              this.toastr.error(
                res.error?.error ||
                  this.translocoService.translate(`'Something went wrong'`)
              );
            }

            this.passwordDataFormIsSubmitting = false;
            this.passwordDataForm.enable({ emitEvent: true });
          },
        });
    }
  }

  goBack() {
    this._location.back();
  }

  openPasswordChangeModal() {
    this.modalEvents.next({ type: 'open' });
  }
}
