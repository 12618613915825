<button
  data-modal-target="create-folder-modal"
  data-modal-toggle="create-folder-modal"
  class="hidden"
  #toggleCreateFolderModalBtn
></button>
<button
  data-modal-target="create-folder-modal"
  data-modal-show="create-folder-modal"
  class="hidden"
  #showCreateFolderModalBtn
></button>
<button
  data-modal-target="create-folder-modal"
  data-modal-hide="create-folder-modal"
  class="hidden"
  #hideCreateFolderModalBtn
></button>
<div
  aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden drop-shadow-3xl fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  id="create-folder-modal"
  tabindex="-1"
>
  <ng-container *transloco="let t;">
    <div
      class="relative p-3 2xl:p-4 w-full lg:w-2/3 2xl:w-1/2 max-w-2xl max-h-full"
    >
      <div class="relative bg-white rounded-3xl shadow overflow-hidden">
        <div class="h-2 bg-orange1"></div>
        <form
          class="p-4 md:p-6"
          [formGroup]="createFolderModalService.createFolderForm"
        >
          <div class="flex items-center justify-between rounded-t">
            <h1
              class="text-lg font-semibold"
              *ngIf="!createFolderModalService.isEditing"
              transloco="Create Folder"
            >
              Create Folder
            </h1>
            <h1
              class="text-lg font-semibold"
              *ngIf="createFolderModalService.isEditing"
              transloco="Edit Folder"
            >
              Edit Folder
            </h1>
            <button
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              (click)="closeModal()"
              [disabled]="createFolderModalService.createFolderForm.disabled"
              type="button"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.3002 5.70998C18.2077 5.61728 18.0978 5.54373 17.9768 5.49355C17.8559 5.44337 17.7262 5.41754 17.5952 5.41754C17.4643 5.41754 17.3346 5.44337 17.2136 5.49355C17.0926 5.54373 16.9827 5.61728 16.8902 5.70998L12.0002 10.59L7.11022 5.69998C7.01764 5.6074 6.90773 5.53396 6.78677 5.48385C6.6658 5.43375 6.53615 5.40796 6.40522 5.40796C6.27429 5.40796 6.14464 5.43375 6.02368 5.48385C5.90272 5.53396 5.79281 5.6074 5.70022 5.69998C5.60764 5.79256 5.5342 5.90247 5.4841 6.02344C5.43399 6.1444 5.4082 6.27405 5.4082 6.40498C5.4082 6.53591 5.43399 6.66556 5.4841 6.78652C5.5342 6.90749 5.60764 7.0174 5.70022 7.10998L10.5902 12L5.70022 16.89C5.60764 16.9826 5.5342 17.0925 5.4841 17.2134C5.43399 17.3344 5.4082 17.464 5.4082 17.595C5.4082 17.7259 5.43399 17.8556 5.4841 17.9765C5.5342 18.0975 5.60764 18.2074 5.70022 18.3C5.79281 18.3926 5.90272 18.466 6.02368 18.5161C6.14464 18.5662 6.27429 18.592 6.40522 18.592C6.53615 18.592 6.6658 18.5662 6.78677 18.5161C6.90773 18.466 7.01764 18.3926 7.11022 18.3L12.0002 13.41L16.8902 18.3C16.9828 18.3926 17.0927 18.466 17.2137 18.5161C17.3346 18.5662 17.4643 18.592 17.5952 18.592C17.7262 18.592 17.8558 18.5662 17.9768 18.5161C18.0977 18.466 18.2076 18.3926 18.3002 18.3C18.3928 18.2074 18.4662 18.0975 18.5163 17.9765C18.5665 17.8556 18.5922 17.7259 18.5922 17.595C18.5922 17.464 18.5665 17.3344 18.5163 17.2134C18.4662 17.0925 18.3928 16.9826 18.3002 16.89L13.4102 12L18.3002 7.10998C18.6802 6.72998 18.6802 6.08998 18.3002 5.70998Z"
                  fill="black"
                />
              </svg>

              <span class="sr-only" transloco="Close modal">Close modal</span>
            </button>
          </div>
          <div class="flex flex-col my-4 gap-2">
            <ng-container *ngIf="createFolderModalService.isEditing">
              <label class="font-semibold text-lg" transloco="Parent Folder"
                >Parent Folder</label
              >
              <app-custom-dropdown
                [buttonText]="
                  createFolderModalService.parentIdControl.value
                    ? requestFolderService.localFolderNameMap.get(
                        createFolderModalService.parentIdControl.value
                      )
                    : '/'
                "
              >
                <ng-template #recursiveList let-list>
                  <li *ngFor="let requestFolder of list" class="border-l">
                    <button
                      class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                      (click)="changeParentFolderValue(requestFolder.id)"
                    >
                      {{ requestFolder.title }}
                    </button>
                    <ul *ngIf="requestFolder.children?.length" class="ml-3">
                      <ng-container
                        *ngTemplateOutlet="
                          recursiveList;
                          context: { $implicit: requestFolder.children }
                        "
                      ></ng-container>
                    </ul>
                  </li>
                </ng-template>
                <li class="border-l">
                  <button
                    class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
                    (click)="changeParentFolderValue(undefined)"
                  >
                    /
                  </button>
                </li>
                <ng-container
                  *ngTemplateOutlet="
                    recursiveList;
                    context: {
                      $implicit:
                        createFolderModalService.availableParentFolderList
                    }
                  "
                ></ng-container>
              </app-custom-dropdown>
            </ng-container>
            <label
              class="font-semibold text-lg mt-3"
              for="title"
              transloco="Title"
              >Title</label
            >
            <input
              id="title"
              [placeholder]="t('Input title...')"
              formControlName="title"
              type="text"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1"
            />
            <div *isSuperAdmin class="flex flex-col my-4">
              <label
                class="font-semibold text-lg"
                for="title"
                transloco="Customer"
                >Customer</label
              >
              <select
                class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 disabled:bg-gray-300"
                formControlName="customer_id"
              >
                <option
                  [value]="customer.id"
                  *ngFor="let customer of allCustomers"
                >
                  {{ customer.label }}
                </option>
              </select>
            </div>
            <div
              class="w-full flex items-center justify-end mt-12 gap-4 flex-wrap"
            >
              <div class="flex-grow flex" *ngIf="createFolderModalService.isEditing">
                <button
                  class="transition-all duration-200 text-white bg-semantic-danger-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
                  type="button"
                  (click)="initDeleteFolder()"
                  [disabled]="
                    createFolderModalService.createFolderForm.disabled
                  "
                  transloco="Delete"
                >
                  Delete
                </button>
              </div>
              <button
                class="transition-all duration-200 text-primary bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
                type="button"
                (click)="closeModal()"
                [disabled]="createFolderModalService.createFolderForm.disabled"
                transloco="Cancel"
              >
                Cancel
              </button>
              <button
                class="duration-200 transition-all text-white bg-primary hover:bg-darkBlue1 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-xs 2xl:text-sm px-4 py-2 2xl:px-6 2xl:py-4 text-center"
                type="button"
                (click)="createFolderModalService.handleSubmit()"
                [disabled]="createFolderModalService.createFolderForm.disabled"
              >
                <ng-container *ngIf="createFolderModalService.isEditing"
                  >Update</ng-container
                >
                <ng-container *ngIf="!createFolderModalService.isEditing"
                  >Create</ng-container
                >
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

  <button
    data-modal-target="requestFolderConfirmDeleteModal"
    data-modal-toggle="requestFolderConfirmDeleteModal"
    class="hidden"
    #requestFolderToggleConfirmDeleteModalBtn
    id="requestFolderToggleConfirmDeleteModalBtn"
  ></button>

  <div
    id="requestFolderConfirmDeleteModal"
    #requestFolderConfirmDeleteModal
    data-delete-type="client"
    tabindex="-1"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-3 2xl:p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow-xl dark:bg-gray-700">
        <button
          type="button"
          class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          data-modal-hide="requestFolderConfirmDeleteModal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only" transloco="Close modal">Close modal</span>
        </button>
        <div class="p-4 md:p-5 text-center">
          <svg
            class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          <h3
            class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
            transloco="This action will delete all subfolders & requests inside. Are you sure you want to remove this Folder?"
          >
            This action will delete all subfolders & requests inside. Are you
            sure you want to remove this Folder?
          </h3>
          <button
            data-modal-hide="requestFolderConfirmDeleteModal"
            type="button"
            (click)="handleFolderDelete()"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
            transloco="Yes"
          >
            Yes
          </button>
          <button
            data-modal-hide="requestFolderConfirmDeleteModal"
            type="button"
            class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            transloco="No"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
