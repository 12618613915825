import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import {TranslocoModule} from "@ngneat/transloco";
import {SharedComponentsModule} from "../shared-components/shared-components.module";

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ProfilePictureComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    SharedComponentsModule,
  ],
  exports: [ProfilePictureComponent],
})
export class AuthModule {}
