import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() type: string = 'text';
  @Input() hasError?: boolean = false;
  @Input() placeholder: string = '';
  @Input() fc: any;
  @Input() id?: string;
  @Input() name?: string;
  @Input() disabled?: boolean;
  @Input() maxlength?: number;
  @Input() minlength?: number;
  @Input() readonly?: boolean;
  @Input() required?: boolean;
  @Input() autocomplete?: string;

  showPassword = false;

  constructor() {}
}
